import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import moment from 'moment';

import SelectProject from '../selectProject';
import SelectDepartment from '../selectDepartment';
import SelectCompany from '../selectCompany';
import AnalyticsRows from '../analyticsRows';

import Input from '../../../../components/input';
import Radio from '../../../../components/radio';
import { FlatButton } from '../../../../components/button';
import EmployeeSuggest from '../../../../components/employee';
import Checkbox from '../../../../components/checkbox';
import SelectWithInput from '../../../../components/SelectWithInput';
import { UploadVoucher } from './components/UploadVoucher';
import { GroupAccomodation } from './components/GroupAccomodation';

import {
  FIELDS,
  FUNDSFIELDS,
  FUNDSLABELS,
  FUNDSFIELDSTYPES,
  PRICEFIELDSSHORT,
  CUSTOM_TYPES,
  TRIPSLABELS,
  PAYMENT_TYPES_CUSTOM_VALUES,
  PAYMENT_TYPES_CUSTOM,
  INN_LENGHTS,
} from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import COMPONENTS from '../../../../bi/constants/components';
import {
  FULLTIME, DEFAULTTIME, DEFAULTDATE,
} from '../../../../constants/time';
import { TYPERATRATES } from '../../../../bi/constants/account';
import { PROVIDERS_MICE } from '../../../../bi/constants/mice';
import { HOTEL_PROVIDER_VALUE } from '../../../../bi/constants/hotel';

import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import {
  applyAnalytics, removeAnalytics, validateSingleAnalytics, validateAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import {
  findDepartments,
  getProviderOldName,
  validateDateAndTime,
  prepareCancellationPenalties,
  isIntNumberOrDash,
} from '../../../../bi/utils/trip';
import {
  getDate,
  isMoment,
  getMoment,
  momentHours,
  momentObject,
  momentMinutes,
  isBeforeDateParameter,
  momentSetHoursAndMinutes,
  getTimeFromTimezoneFormat,
} from '../../../../bi/utils/formatDate';
import { formatDate } from '../../../../utils/date';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import renderFundsForm from '../form';

import style from '../../styles/form.module.scss';

const LABELS = {
  DESCRIPTION: 'Наименование изменения',
  NAME_DESCRIPTION: 'Описание изменения',
  VOUCHER_INFO: 'Данные ваучера',
  NAME: 'Название услуги',
  EMPLOYEES: 'Сотрудник',
  PROVIDER_NAME: 'Поставщик услуги',
  TRAIN: 'Ж/д билет',
  CHECKIN_DATE: 'Дата начала услуги',
  CHECKIN_TIME: 'Время начала услуги',
  CHECKOUT_DATE: 'Дата окончания услуги',
  CHECKOUT_TIME: 'Время окончания услуги',
  BASE: 'Стоимость',
  COMMISSION: 'Наценка Smartway',
  ADD_TRIP: 'Добавить заказ',
  PRICE: 'Цена',
  ORDER_ID: 'Номер бронирования у поставщика',
  FINANCE_OPERATIONS: 'Финансовые операции',
  CONFIRM: 'Подтвердить изменения',
  TYPE: 'Тип услуги',
  PAYMENT_TYPE: 'Тип оплаты',
  ADD_NEW_VOUCHER: 'Добавить новый ваучер',
  DELETE_VOUCHER: 'Удалить ваучер',
};

const SERVICE_NAME = 'custom';

const LABELS_VAT = {
  WITH: 'С НДС',
  WITHOUT: 'Без НДС',
};

const VAT_VALUE = {
  WITH: true,
  WITHOUT: false,
};

export default class NewCustomForm extends Component {
  static propTypes = {
    custom: PropTypes.object,
    companies: PropTypes.array,
    tripService: PropTypes.object,
    projects: PropTypes.array,
    mandatoryProject: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    addTrip: PropTypes.func,
    guidService: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    tripService: [],
    custom: [],
    companies: [],
    employees: [],
    projects: [],
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    isEditing: false,
    setTripAnalytics: () => {},
    addTrip: () => {},
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super(props);
    const { custom } = props;

    this.state = { ...this.setCustom(custom) };
  }

  componentDidMount() {
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);

    this.handleProviderFocus();
    this.getCustomTypes();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.custom.OrderTripItemId !== this.state.OrderTripItemId) {
      this.state = this.setCustom(newProps.custom);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList, customFile }) => this.setState({ employeesList, customFile });

  setCustom = (custom) => {
    const { customFile } = this.props.tripService.get();
    const {
      isEditing, mandatoryProject, tripService: { addVouchers },
    } = this.props;
    const {
      isEdit,
      OrderTripItemId,
      ServiceType,
      Employees,
      JsonData,
      PriceDetails,
      UserAnalytics,
      CompanyId,
      ProjectId,
      DepartmentId,
      ProviderName,
      OrderPaymentType,
      ProviderInn,
      ReportAmount,
    } = custom;
    const data = typeof JsonData === 'string' ? JSON.parse(JsonData) : JsonData;

    const { PriceDetails: PriceDetailsJson, Hotel } = data;
    const newMandatory = isMandatory(mandatoryProject, CompanyId);

    const checkinTime = data.CheckinDate ? getTimeFromTimezoneFormat(data.CheckinDate) : '';
    const checkoutTime = data.CheckoutDate ? getTimeFromTimezoneFormat(data.CheckoutDate) : '';

    const state = (json) => ({
      mandatoryProject: newMandatory,
      serviceTypes: [],
      paymentTypes: PAYMENT_TYPES_CUSTOM,
      OrderPaymentType: isEditing ? OrderPaymentType : OrderPaymentType || PAYMENT_TYPES_CUSTOM_VALUES.CARD,
      ProviderInn: ProviderInn || '',
      ReportAmount: ReportAmount || 0,
      JsonData: {
        ...json,
        CheckinDate: momentObject(json.CheckinDate),
        CheckoutDate: momentObject(json.CheckoutDate),
        CheckinTime: moment(checkinTime, DEFAULTTIME),
        CheckoutTime: moment(checkoutTime, DEFAULTTIME),
        Hotel: json.Hotel
          ? {
            ...json.Hotel,
            CancellationPenalties: prepareCancellationPenalties(json),
          }
          : null,
      },
    });

    if (isEdit) {
      const jsonData = {
        ...state(JsonData),
        JsonData: {
          ...state(JsonData).JsonData,
          CheckinTime: moment(formatDate(moment(data.CheckinTime), DEFAULTTIME), DEFAULTTIME),
          CheckoutTime: moment(formatDate(moment(data.CheckoutTime), DEFAULTTIME), DEFAULTTIME),
        },
      };

      return {
        ...custom,
        ...jsonData,
        ProjectId: ProjectId || 0,
      };
    }

    if (isEditing) {
      const currentData = JSON.parse(JsonData);
      const currentEmployeeId = custom && Employees && Employees[0] && Employees[0].Id;
      const hotelFields = currentData.Hotel
        ? {
          ...currentData.Hotel,
          CancellationPenalties: currentData.Hotel.CancellationPenalties
            ? currentData.Hotel.CancellationPenalties.map((item) => ({
              ...item,
              From: momentObject(item.From),
            }))
            : null,
        }
        : {
          Name: '',
          Address: '',
          Comment: '',
          FreeCancellation: '',
          CancellationPenalties: [],
        };

      const hotelFieldsGroup = currentData.Type === CUSTOM_TYPES.GROUP_ACCOMODATION ? hotelFields : null;

      const jsonData = {
        ...currentData,
        ProviderName,
        CheckinTime: moment(checkinTime, DEFAULTTIME),
        CheckoutTime: moment(checkoutTime, DEFAULTTIME),
        Hotel: hotelFieldsGroup,
      };

      const customVoucher = {
        file: jsonData.VoucherFileName ? new File([jsonData.VoucherFileName], jsonData.FileName) : null,
        uploaded: !!jsonData.VoucherFileName,
        guid: jsonData.VoucherFileName,
      };

      addVouchers([customVoucher]);

      return {
        ...state(jsonData),
        Description: '',
        OrderTripItemId,
        ServiceType,
        Status: 1,
        Employees: Employees[0] || [],
        CompanyId: CompanyId || 0,
        EmployeeId: currentEmployeeId,
        ProjectId: ProjectId || 0,
        Providers: ProviderName || '',
        DepartmentId: DepartmentId || 0,
        Departments: [],
        UserAnalytics: UserAnalytics || [],
        mandatoryProject: newMandatory,
        PriceDetails: {
          ...PriceDetailsJson,
          commission: PriceDetailsJson.Commission,
          hasVat: PriceDetailsJson.HasVAT,
        },
        Surcharges: {
          Enabled: false,
          Base: 0,
          Commission: 0,
          Description: '',
        },
        hotelName: {
          label: Hotel ? Hotel.Name : '',
          selected: {},
          suggests: [],
        },

        validation: {
          Name: '',
          CheckinDate: '',
          CheckinTime: '',
          CheckoutDate: '',
          CheckoutTime: '',
          Employees: '',
          CompanyId: '',
          ProjectId: '',
          Providers: '',
          DepartmentId: '',
          Description: '',
          Base: '',
          Сommission: '',
          ProviderName: '',
          analytics: {},
        },

        employeesList: [],
        providersList: [],

        customFile: [
          {
            ...customFile[0],
            ...customVoucher,
          },
        ],
        isLoading: false,
      };
    }

    return {
      ...state(JsonData),
      OrderTripItemId,
      ServiceType,
      Status: 0,
      Employees,
      CompanyId: 0,
      EmployeeId: 0,
      PriceDetails,
      ProjectId: 0,
      Providers: '',
      DepartmentId: 0,
      Departments: [],
      UserAnalytics: UserAnalytics || [],
      hotelName: {
        label: Hotel ? Hotel.Name : '',
        selected: {},
        suggests: [],
      },

      validation: {
        Name: '',
        CheckinDate: '',
        CheckinTime: '',
        CheckoutDate: '',
        CheckoutTime: '',
        Employees: '',
        CompanyId: '',
        ProjectId: '',
        Providers: '',
        DepartmentId: '',
        Base: '',
        commission: '',
        ProviderName: '',
        ProviderInn: '',
        analytics: {},
      },

      employeesList: [],
      providersList: [],

      customFile,
      isLoading: false,
    };
  };

  normalizeDate(JsonData) {
    const newJsonData = JsonData;
    const checkDate = formatDate(
      momentSetHoursAndMinutes(
        newJsonData.CheckinDate, momentHours(newJsonData.CheckinTime), momentMinutes(newJsonData.CheckinTime),
      ),
      FULLTIME,
    );
    const checkoutDate = formatDate(
      momentSetHoursAndMinutes(
        newJsonData.CheckoutDate, momentHours(newJsonData.CheckoutTime), momentMinutes(newJsonData.CheckoutTime),
      ),
      FULLTIME,
    );

    newJsonData.CheckinDate = checkDate || null;
    newJsonData.CheckoutDate = checkoutDate || null;

    if (JsonData.Type === CUSTOM_TYPES.GROUP_ACCOMODATION) {
      const cancellationPenalty = JsonData.Hotel.FreeCancellation && JsonData.Hotel.CancellationPenalties
        ? JsonData.Hotel.CancellationPenalties.map(({
          From, Total, Base, Additional,
        }) => ({
          From: From ? From.format(FULLTIME) : '',
          Total: parseFloat(Total, 10) || 0,
          Base: parseFloat(Base, 10) || 0,
          Additional,
        }))
        : [];

      newJsonData.Hotel.CancellationPenalties = cancellationPenalty;
    }
  }

  handleChangeInput = (e, field, value) => {
    const part = field.split('.');

    if (part.length === 1) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          [field]: value,
        },
      });
    }

    return this.setState({
      JsonData: {
        ...this.state.JsonData,
        [part[0]]: {
          ...this.state.JsonData[part[0]],
          [part[1]]: value,
        },
      },
    });
  };

  handleChangePriceInput = (e, field, value) => {
    const { isEditing } = this.props;

    if (isEditing) {
      return this.setState({
        JsonData: {
          ...this.state.JsonData,
          PriceDetails: {
            ...this.state.JsonData.PriceDetails,
            [field]: value,
          },
        },
      });
    }

    return this.setState({
      PriceDetails: {
        ...this.state.PriceDetails,
        [field]: value,
      },
    });
  };

  handleChangeDate = (field, value) => {
    this.setState({
      JsonData: {
        ...this.state.JsonData,
        [field]: value,
      },
    });
  };

  handleCheckbox = (e, field, value) => {
    this.setState({
      JsonData: {
        ...this.state.JsonData,
        [field]: value,
      },
    });
  };

  handleSendVouchers = async () => {
    const { customFile } = this.state;
    const { tripService: { uploadCustomFiles } } = this.props;

    const files = customFile.filter(({ file }) => !!file).map(({ file }) => file);

    if (customFile[0].guid) return customFile[0].guid;

    if (!files.length) return '';

    const fileId = await uploadCustomFiles(files);

    return fileId;
  };

  handleConfirm = async () => {
    const {
      OrderTripItemId,
      CompanyId,
      ServiceType,
      Status,
      PriceDetails,
      JsonData,
      Employees,
      Description,
      Surcharges,
      ProjectId,
      DepartmentId,
      ProviderInn,
      ReportAmount,
      OrderPaymentType,
      UserAnalytics = [],
      customFile,
    } = this.state;
    const {
      addTrip, guidService, onConfirm, custom, isEditing, tripService: { resetCustomFile },
    } = this.props;

    const { isValid, validation } = this.validationSubmit({
      Employees, JsonData, PriceDetails, CompanyId, ProjectId, DepartmentId,
    });

    if (isValid) {
      this.setState({ isLoading: true });
      const Guid = guidService.generate();
      const stateToSave = JSON.parse(JSON.stringify(this.state));
      const { Hotel } = JsonData;
      stateToSave.Guid = Guid;
      stateToSave.customFile = customFile;

      JsonData.EmployeeIds = [Employees.Id];
      JsonData.VoucherFileName = await this.handleSendVouchers();
      JsonData.Hotel = Hotel
        ? {
          ...JsonData.Hotel,
          CancellationPenalties: Hotel.CancellationPenalties.length
            && Object.keys(Hotel.CancellationPenalties[0]).every((key) => !Hotel.CancellationPenalties[0][key])
            ? null
            : Hotel.CancellationPenalties.filter((item) => !Object.keys(item).every((key) => !item[key])),
        }
        : null;

      this.normalizeDate(JsonData);

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        EmployeesList: Employees,
        ProviderInn,
        ReportAmount: ReportAmount || null,
        OrderPaymentType,

        Status,
        ServiceType,

        UserAnalytics,
        Guid,
        indexEdited: custom.indexEdited,

        JsonData: JSON.stringify(JsonData),
      };

      if (isEditing) {
        item.Description = Description;
        item.Surcharges = Surcharges.Enabled ? [lodashReplaces.omit(Surcharges, FIELDS.ENABLED)] : [];
      } else {
        item.PriceDetails = PriceDetails;
      }

      resetCustomFile();
      addTrip(stateToSave);
      onConfirm(item);
    } else {
      scrollToErrMessage();
      this.setState({ validation: { ...validation } });
    }
    this.setState({ isLoading: false });
  };

  handleSelectEmployee = async ({ Id }) => {
    const { tripService } = this.props;
    const currentItem = await tripService.getEmployeeDataByID(Id);
    const { CompanyId } = this.state;

    const result = this.props.tripService.customTripFieldValidation('Employees', currentItem);

    this.setState({
      Employees: currentItem,
      Departments: findDepartments(currentItem, CompanyId),
      EmployeeId: currentItem.Id,
      validation: {
        ...this.state.validation,
        Employees: result,
      },
    });
  };

  handleRemoveEmployee = () => {
    this.setState({
      Employees: {},
      Departments: [],
      DepartmentId: 0,
    });
  };

  handleProviderFocus = async () => {
    const { tripService } = this.props;

    const result = await tripService.getAllProviders(SERVICE_NAME);

    this.setState({ providersList: result });
  };

  getCustomTypes = async () => {
    const { tripService } = this.props;
    const { JsonData } = this.state;

    const result = await tripService.getTypesCustom();

    this.setState({
      serviceTypes: result,
      JsonData: {
        ...JsonData,
        Type: JsonData.Type || result[0],
      },
    });
  };

  handleSelectProvider = (value) => {
    const { label } = value;
    const { tripService, isEditing } = this.props;

    if (isEditing && label === '') {
      return;
    }

    const result = tripService.customTripFieldValidation(FIELDS.PROVIDERS, label);

    this.setState({
      Providers: value,
      JsonData: {
        ...this.state.JsonData,
        ProviderName: label,
      },
      validation: {
        ...this.state.validation,
        Providers: result,
      },
    });
  };

  uploadFile = (file, ind) => {
    const { tripService: { uploadCustomFile } } = this.props;

    uploadCustomFile(file, ind);
  };

  handleAddNewVoucher = () => {
    const { tripService: { addNewVoucher } } = this.props;

    addNewVoucher();
  };

  handleSelectCompany = ({ id }) => {
    const { tripService, mandatoryProject } = this.props;
    const newMandatory = isMandatory(mandatoryProject, id);
    const result = tripService.customTripFieldValidation(FIELDS.COMPANYID, id);
    const projectResult = newMandatory ? tripService.customTripFieldValidation(FIELDS.PROJECTID, null) : '';

    this.setState({
      CompanyId: id,
      Employees: {},
      mandatoryProject: newMandatory,
      validation: {
        ...this.state.validation,
        ProjectId: projectResult,
        CompanyId: result,
        Employees: '',
      },
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? tripService.customTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? tripService.customTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  handleChangeVat = (field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      hasVat: value === 'true',
    },
  });

  validationSubmit = ({
    Employees, JsonData, PriceDetails, CompanyId, ProjectId, DepartmentId,
  }) => {
    const {
      tripService,
      analytics,
      isEditing,
      projects,
      isTripPaidByPersonalFunds,
      tripAnalyticsValueIds: tripAnalytics,
    } = this.props;
    const {
      Departments,
      mandatoryProject,
      Providers,
      Description,
      OrderPaymentType,
      ProviderInn,
      UserAnalytics: serviceAnalytics,
    } = this.state;

    const projectMassage = projects.length
      ? tripService.customTripFieldValidation(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    const name = tripService.customTripFieldValidation(FIELDS.NAME, JsonData.Name);
    const checkinDate = tripService.customTripFieldValidation(FIELDS.CHECKIN_DATE, JsonData.CheckinDate);
    const orderId = tripService.customTripFieldValidation(FIELDS.ORDER_ID, JsonData.OrderId);
    const checkinTime = tripService.customTripFieldValidation(FIELDS.CHECKINTIME, JsonData.CheckinTime);
    const checkoutTime = tripService.customTripFieldValidation(FIELDS.CHECKOUTTIME, JsonData.CheckoutTime);
    const employees = tripService.customTripFieldValidation(FIELDS.EMPLOYEES, Employees);
    const companyId = tripService.customTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject && !isTripPaidByPersonalFunds ? projectMassage : '';
    const providers = !Providers.label && !JsonData.ProviderName.length
      ? tripService.customTripFieldValidation(FIELDS.PROVIDERS, Providers.label)
      : '';
    const departmentId = Departments.length > 0
      ? tripService.customTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId)
      : '';
    const price = tripService.customTripFieldValidation(FUNDSFIELDS.BASE, PriceDetails.Base);
    const commissionPrice = tripService.customTripFieldValidation(FIELDS.COMMISSION, PriceDetails.commission);
    const commissionPriceEdit = isEditing
      ? tripService.customTripFieldValidation(PRICEFIELDSSHORT.COMMISSION, PriceDetails.Commission)
      : '';
    const userAnalytics = validateAnalytics(tripAnalytics, serviceAnalytics, analytics);
    const hasUnsetRequiredUserAnalytics =
      !isTripPaidByPersonalFunds && Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);
    const description = isEditing
      ? tripService.customTripFieldValidation(FIELDS.DESCRIPTION, Description)
      : '';
    const inn = OrderPaymentType === PAYMENT_TYPES_CUSTOM_VALUES.ACCOUNT
      ? tripService.hotelTripFieldValidation(FIELDS.PROVIDER_INN, ProviderInn)
      : '';

    let checkoutDate;

    if (isMoment(JsonData.CheckinDate)
      && JsonData.CheckinDate.isValid()
      && isMoment(JsonData.CheckoutDate)
      && JsonData.CheckoutDate.isValid()) {
      checkoutDate = tripService.customTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      checkoutDate = tripService.customTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData.CheckoutDate);
      checkoutDate = tripService.customTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    }

    if (
      name
      || checkinDate
      || checkoutDate
      || checkinTime
      || checkoutTime
      || employees
      || companyId
      || projectId
      || providers
      || departmentId
      || price
      || commissionPrice
      || hasUnsetRequiredUserAnalytics
      || orderId
      || description
      || commissionPriceEdit
      || inn
    ) {
      return {
        isValid: false,
        validation: {
          Name: name,
          CheckinDate: checkinDate,
          CheckoutDate: checkoutDate,
          CheckinTime: checkinTime,
          CheckoutTime: checkoutTime,
          Employees: employees,
          Base: price,
          commission: commissionPrice,
          CompanyId: companyId,
          ProjectId: projectId,
          Providers: providers,
          DepartmentId: departmentId,
          MandatoryProject: mandatoryProject,
          analytics: userAnalytics,
          OrderId: orderId,
          Description: description,
          Commission: commissionPriceEdit,
          ProviderInn: inn,
        },
      };
    }

    return { isValid: true };
  };

  validationProvider = (e, field) => {
    const result = this.props.tripService.customTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationInput = (e, field) => {
    const result = this.props.tripService.customTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationHistory = (e, field) => {
    const result = this.props.tripService.customTripFieldValidation(field, this.state[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationDate = (field, value) => {
    const { JsonData } = this.state;
    const { tripService: { customTripFieldValidation }, isEditing } = this.props;

    const {
      result,
      resultCheckin,
      resultCheckout,
      resultCheckoutLess,
    } = validateDateAndTime(field, value, isEditing, JsonData, customTripFieldValidation);

    if (resultCheckin || resultCheckout || resultCheckoutLess) {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
          CheckinDate: resultCheckin,
          CheckoutDate: resultCheckout || resultCheckoutLess,
        },
      });
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result || '',
          CheckinDate: '',
          CheckoutDate: '',
        },
      });
    }
  };

  validationPrice = (e, field) => {
    const result = this.props.tripService.customTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  handleEmployeeFocus = async () => {
    const { tripService } = this.props;
    const { CompanyId } = this.state;

    await tripService.autocompleteEmployees(CompanyId, '');

    this.setState({
      validation: {
        ...this.state.validation,
        Employees: +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
      },
    });
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleChangeFinancesEnabled = (e, field, value) => {
    const part = field.split('.');

    return this.setState({
      [part[0]]: {
        ...this.state[part[0]],
        [part[1]]: value,
      },
    });
  };

  handleChangeDescription = (e, field, value) => this.setState({ Description: value });

  handleChangeSurcharges = (e, field, value) => this.setState({
    Surcharges: {
      ...this.state.Surcharges,
      [field]: value,
    },
  });

  handleChangeSelect = ({ target: { value } }) => {
    this.setState({
      JsonData: {
        ...this.state.JsonData,
        Type: value,
        Hotel: value === CUSTOM_TYPES.GROUP_ACCOMODATION
          ? {
            Name: '',
            Address: '',
            Comment: '',
            FreeCancellation: '',
            CancellationPenalties: [
              {
                From: null, Total: '', Base: '', Additional: false,
              },
            ],
          }
          : null,
      },
    });
  };

  handleChangePaymentType = ({ target: { value } }) => {
    this.setState({
      OrderPaymentType: value,
      ProviderInn: '',
    });
  };

  handleSelectSuggest = (hotel) => {
    const { tripService } = this.props;

    tripService.getHotelDetails(hotel.Id).then((res) => {
      const { Name, Address } = res;

      this.setState({
        hotelName: {
          ...this.state.hotelName,
          label: Name,
        },
        JsonData: {
          ...this.state.JsonData,
          Hotel: {
            ...this.state.JsonData.Hotel,
            Name,
            Address,
          },
        },
      });
    });
  };

  handleChangeSuggest = (value) => {
    const { tripService } = this.props;

    this.setState({
      hotelName: {
        ...this.state.hotelName,
        label: value,
      },
      JsonData: {
        ...this.state.JsonData,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name: value,
          ClassificatorId: null,
        },
      },
    }, () => {
      const hotelLabel = this.state.hotelName.label;

      if (hotelLabel) {
        tripService.autocompleteHotel(hotelLabel).then((res) => {
          const resFilter = res ? res.filter(({ IsRegion }) => !IsRegion) : [];

          this.setState({
            hotelName: {
              ...this.state.hotelName,
              suggests: resFilter,
            },
          });
        });
      }
    });
  };

  handleChangeHotel = (field, value) => {
    const { JsonData } = this.state;
    const { Hotel } = JsonData;

    const newCancellationPenalties = !value && field === FIELDS.FREE_CANCELLATION
      ? null
      : JsonData.Hotel.CancellationPenalties;

    return this.setState({
      JsonData: {
        ...JsonData,
        Hotel: {
          ...Hotel,
          [field]: value,
          CancellationPenalties: newCancellationPenalties,
        },
      },
    });
  };

  handleAddCancellation = () => {
    const { JsonData } = this.state;
    const { Hotel } = JsonData;

    let newCancellationPenalties = [];

    if (Hotel.CancellationPenalties) {
      newCancellationPenalties = [...Hotel.CancellationPenalties];
    }

    newCancellationPenalties.push({
      From: null, Total: '', Base: '', Additional: false,
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Hotel: {
          ...Hotel,
          CancellationPenalties: newCancellationPenalties,
        },
      },
    });
  };

  handleRemoveCancellation = (i) => {
    const { JsonData } = this.state;
    const { Hotel } = JsonData;

    const newCancellationPenalties = [...Hotel.CancellationPenalties];

    const changeItems = (index) => [
      ...newCancellationPenalties.slice(0, index),
      ...newCancellationPenalties.slice(index + 1),
    ];

    this.setState({
      JsonData: {
        ...JsonData,
        Hotel: {
          ...Hotel,
          CancellationPenalties: changeItems(i),
        },
      },
    });
  };

  handleChangePenaltyInput = (field, value, i) => {
    const { JsonData } = this.state;
    const { Hotel } = JsonData;

    const cancellationPenalties = Hotel.CancellationPenalties.map((item, index) => {
      if (index === i) {
        const penalty = item;
        penalty[field] = value;

        return penalty;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        Hotel: {
          ...Hotel,
          CancellationPenalties: cancellationPenalties,
        },
      },
    });
  };

  handleChangeNumberInput = (field, value) => {
    if ((value === '' || isIntNumberOrDash(value)) && value.length <= INN_LENGHTS.MAX) {
      this.setState({ [field]: value });
    }
  };

  handleChangeReportAmount = (field, value) => {
    this.setState({ [field]: typeof value === 'number' ? parseFloat(value.toFixed(2)) : value });
  };

  validateProviderInn = (e, field) => {
    const { ProviderInn, validation } = this.state;

    const result = this.props.tripService.customTripFieldValidation(field, ProviderInn);

    this.setState({
      validation: {
        ...validation,
        ProviderInn: result,
      },
    });
  };

  renderCompany = () => {
    const { companies } = this.props;
    const { CompanyId, validation } = this.state;

    return (
      <SelectCompany
        currentCompanyId={ CompanyId }
        companies={ companies }
        onSelect={ this.handleSelectCompany }
        validationCompany={ validation.CompanyId }
      />
    );
  };

  renderAnalytics = () => {
    const {
      analytics,
      tripAnalyticsValueIds,
      isTripPaidByPersonalFunds,
    } = this.props;
    const { UserAnalytics, validation } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <AnalyticsRows
        analytics={ analytics }
        serviceAnalyticsValueIds={ UserAnalytics }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        onSelect={ this.handleSelectAnalytics }
        validation={ validation.analytics }
      />
    );
  };

  renderProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const {
      ProjectId, validation, CompanyId, mandatoryProject,
    } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        disabled={ !CompanyId }
        currentProjectId={ ProjectId }
        validationProject={ validation.ProjectId }
        mandatoryProject={ mandatoryProject }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  renderProvider = () => {
    const {
      Providers, validation, providersList, JsonData: { ProviderName },
    } = this.state;

    const providerOldName = getProviderOldName(providersList, ProviderName);

    const currentLabel = (providersList.length && providerOldName) ? providerOldName : Providers;

    const providersListAndInput = [
      ...providersList,
      ...PROVIDERS_MICE,
      {
        id: 0, type: TYPERATRATES.OPTIONAL, value: null, label: '',
      },
    ];

    return (
      <SelectWithInput
        label={ LABELS.PROVIDER_NAME }
        items={ providersListAndInput }
        currentItem={ currentLabel }
        onChange={ this.handleSelectProvider }
        typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
        valid={ validation.Providers }
        onScroll
      />
    );
  };

  renderFinanceOperations = () => {
    const { Surcharges } = this.state;

    const financeFieldsHtml = Surcharges.Enabled
      ? renderFundsForm(Surcharges, this.handleChangeSurcharges, FUNDSFIELDSTYPES.SURCHARGES)
      : null;

    return (
      <div className={ `${style.row} ${style.panel}` }>
        <h4>{ LABELS.FINANCE_OPERATIONS }</h4>
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <Checkbox
              field={ FUNDSFIELDS.SURCHARGES }
              label={ FUNDSLABELS.SURCHARGES_CUSTOM }
              value={ Surcharges.Enabled }
              onChange={ this.handleChangeFinancesEnabled }
            />
            { financeFieldsHtml }
          </div>
        </div>
      </div>
    );
  };

  renderVatButtons = () => {
    if (this.props.isEditing) return null;

    const { PriceDetails } = this.state;

    return (
      <div className={ `${style['col-1-4']} ${style['vat-action']}` }>
        <Radio
          label={ LABELS_VAT.WITH }
          field={ FIELDS.HASVAT }
          checked={ PriceDetails.hasVat === VAT_VALUE.WITH }
          value={ VAT_VALUE.WITH }
          onChange={ (field, value) => this.handleChangeVat(field, value) }
        />
        <Radio
          label={ LABELS_VAT.WITHOUT }
          field={ FIELDS.HASVAT }
          checked={ PriceDetails.hasVat === VAT_VALUE.WITHOUT }
          value={ VAT_VALUE.WITHOUT }
          onChange={ (field, value) => this.handleChangeVat(field, value) }
        />
      </div>
    );
  };

  renderEditHistory = () => {
    if (!this.props.isEditing) return null;

    const { Description, validation } = this.state;

    return (
      <div className={ `${style.row} ${style.panel}` } ref={ (ref) => { this.refDescription = ref; } }>
        <h4>{ LABELS.NAME_DESCRIPTION }</h4>
        <div className={ style.row } >
          <Input
            field={ FIELDS.DESCRIPTION }
            value={ Description }
            label={ LABELS.DESCRIPTION }
            onChange={ this.handleChangeDescription }
            onBlur={ this.validationHistory }
            valid={ validation.Description }
          />
        </div>
      </div>
    );
  };

  renderOptions = () => this.state.serviceTypes.map((type, id) => (
    <option key={ id } value={ type }>{ type }</option>
  ));

  renderPaymentOptions = () => this.state.paymentTypes.map(({ value, label }) => (
    <option key={ value } value={ value }>{ label }</option>
  ));

  renderGroupAccomodation = () => {
    const { JsonData: { Type } } = this.state;

    if (Type !== CUSTOM_TYPES.GROUP_ACCOMODATION) return null;

    const {
      JsonData: {
        Hotel: {
          Address, Comment, CancellationPenalties, FreeCancellation,
        },
      },
      hotelName,
    } = this.state;

    return (
      <div className={ `${style.row} ${style.panel}` }>
        <h4>{ CUSTOM_TYPES.GROUP_ACCOMODATION }</h4>
        <GroupAccomodation
          hotelName={ hotelName }
          address={ Address }
          comment={ Comment }
          cancellationPenalties={ CancellationPenalties }
          freeCancellation={ FreeCancellation }
          onChangeInput={ this.handleChangeHotel }
          onSelectSuggest={ this.handleSelectSuggest }
          onChangeSuggest={ this.handleChangeSuggest }
          onAddCancellation={ this.handleAddCancellation }
          onRemoveCancellation={ this.handleRemoveCancellation }
          onChangePenaltyInput={ this.handleChangePenaltyInput }
        />
      </div>
    );
  };

  renderDay = (items, currentDate) => {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return <td { ...items } className={ `${items.className} ${style['date-before']}` }>{ currentDateFormat }</td>;
    }

    return <td { ...items }>{ currentDateFormat }</td>;
  };

  renderUploadVoucher = () => {
    const { customFile } = this.state;
    const { tripService: { resetCustomFile } } = this.props;

    return customFile.map((file, ind) => {
      const buttonAddVoucherHtml = file.file && ind === customFile.length - 1 && !file.guid
        ? (
          <div className={ style['col-1-4'] }>
            <FlatButton
              label={ LABELS.ADD_NEW_VOUCHER }
              onClick={ this.handleAddNewVoucher }
            />
          </div>
        )
        : null;

      return (
        <div className={ style.row }>
          <div className={ style['col-1-2'] }>
            <UploadVoucher
              uploadFile={ (item) => this.uploadFile(item, ind) }
              customFile={ file }
              resetCustomFile={ () => resetCustomFile(ind) }
            />
          </div>
          { buttonAddVoucherHtml }
        </div>
      );
    });
  };

  renderSelectOrderPayment = () => {
    const { OrderPaymentType } = this.state;

    if (!OrderPaymentType) return null;

    return (
      <div className={ style.row }>
        <div className={ style['col-1-3'] }>
          <div className={ style.select }>
            <div className={ style.wrap }>
              <label>{ LABELS.PAYMENT_TYPE }</label>
              <select
                value={ OrderPaymentType }
                onChange={ this.handleChangePaymentType }
              >
                { this.renderPaymentOptions() }
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderINN = () => {
    const {
      OrderPaymentType,
      ProviderInn,
      validation: { ProviderInn: validationInn },
    } = this.state;

    if (OrderPaymentType !== PAYMENT_TYPES_CUSTOM_VALUES.ACCOUNT) return null;

    return (
      <div className={ style['col-1-4'] }>
        <Input
          field={ FIELDS.PROVIDER_INN }
          value={ ProviderInn }
          label={ TRIPSLABELS.INN }
          onChange={ (e, field, value) => this.handleChangeNumberInput(field, value) }
          onBlur={ this.validateProviderInn }
          valid={ validationInn }
        />
      </div>
    );
  };

  renderReportAmount = () => {
    const { isEditing } = this.props;
    const { JsonData: { ProviderName, Type }, ReportAmount } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.aanda
      || Type !== CUSTOM_TYPES.GROUP_ACCOMODATION) return null;

    return (
      <div className={ style.row }>
        <div className={ style['col-1-4'] }>
          <Input
            field={ FIELDS.REPORT_AMOUNT }
            value={ ReportAmount }
            label={ TRIPSLABELS.REPORT_AMOUNT }
            onChange={ (e, field, value) => this.handleChangeReportAmount(field, value) }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            disabled={ isEditing }
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      PriceDetails,
      Employees,
      validation,
      Departments,
      DepartmentId,
      EmployeeId,
      employeesList,
      CompanyId,
      isEdit,
      isLoading,
      JsonData: { PriceDetails: PriceDetailsJson },
    } = this.state;
    const { isEditing } = this.props;

    const data = this.state.JsonData;

    const validCheckinDateHtml = validation.CheckinDate ? <span className='error-msg'>{ validation.CheckinDate }</span> : null;
    const validCheckinTimeHtml = validation.CheckinTime ? <span className='error-msg'>{ validation.CheckinTime }</span> : null;
    const validCheckoutDateHtml = validation.CheckoutDate ? <span className='error-msg'>{ validation.CheckoutDate }</span> : null;
    const validCheckoutTimeHtml = validation.CheckoutTime ? <span className='error-msg'>{ validation.CheckoutTime }</span> : null;

    const financeOperationsHtml = isEditing ? this.renderFinanceOperations() : null;
    const labelButton = isEditing || isEdit ? LABELS.CONFIRM : LABELS.ADD_TRIP;

    const priceField = isEditing ? PriceDetailsJson : PriceDetails;
    const priceFieldCommission = isEditing ? PriceDetailsJson.Commission : PriceDetails.commission;
    const priceFieldName = isEditing ? PRICEFIELDSSHORT.COMMISSION : FIELDS.COMMISSION;
    const priceValidation = isEditing ? validation.Commission : validation.commission;

    const selectProjectsHtml = this.renderProject();
    const selectCompanyHtml = this.renderCompany();

    return (
      <div className={ style.wrap }>
        { this.renderEditHistory() }
        <div className={ `${style.row} ${style.panel}` }>
          <h4>{ LABELS.VOUCHER_INFO }</h4>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                field={ FIELDS.NAME }
                value={ data.Name }
                label={ LABELS.NAME }
                onChange={ this.handleChangeInput }
                onBlur={ this.validationInput }
                valid={ validation.Name }
              />
            </div>
            { selectCompanyHtml }
            { selectProjectsHtml }
          </div>
          { this.renderAnalytics() }
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <div className={ style.select }>
                <div className={ style.wrap }>
                  <label>{ LABELS.TYPE }</label>
                  <select
                    value={ data.Type }
                    onChange={ this.handleChangeSelect }
                  >
                    { this.renderOptions() }
                  </select>
                </div>
              </div>
            </div>
          </div>
          { this.renderSelectOrderPayment() }
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <EmployeeSuggest
                items={ employeesList }
                selected={ Employees }
                placeholder={ LABELS.EMPLOYEES }
                onRemove={ this.handleRemoveEmployee }
                onSelect={ this.handleSelectEmployee }
                valid={ validation.Employees }
                onFocus={ this.handleEmployeeFocus }
                onChange={ this.handleChangeEmployee }
                disablePick={ !CompanyId }
              />
            </div>
            <SelectDepartment
              departments={ Departments }
              onSelect={ this.handleSelectDepartment }
              validationDepartment={ validation.DepartmentId }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ EmployeeId }
            />
          </div>
          <div className={ `${style.row} ${style.row_service}` }>
            <div className={ style['col-1-4'] }>
              <div className={ `${style.wrap} ${validation.OrderId ? style['no-valid-company'] : ''}` }>
                <Input
                  field={ FIELDS.ORDER_ID }
                  value={ data.OrderId }
                  label={ LABELS.ORDER_ID }
                  onChange={ this.handleChangeInput }
                  onBlur={ this.validationProvider }
                  valid={ validation.OrderId }
                />
              </div>
            </div>
            <div className={ `${style['col-1-4']} ${style.alignment}` }>
              <div className={ `${style.wrap} ${validation.ProviderName ? style['no-valid-company'] : ''}` }>
                { this.renderProvider() }
              </div>
            </div>
            { this.renderINN() }
          </div>
          <div className={ `${style.row} ${style['form-panel']}` }>
            <div className={ style['col-1-4'] }>
              <DatePicker
                dateFormat={ DEFAULTDATE }
                timeFormat={ false }
                renderDay={ this.renderDay }
                locale='ru'
                value={ data.CheckinDate }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKIN_DATE, value) }
                onBlur={ () => this.validationDate(FIELDS.CHECKIN_DATE, data.CheckinDate) }
                className={ `componentHook ${validation.CheckinDate ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKIN_DATE }</label>
              { validCheckinDateHtml }
            </div>
            <div className={ style['col-1-4'] }>
              <DatePicker
                dateFormat={ false }
                timeFormat={ DEFAULTTIME }
                locale='ru'
                value={ data.CheckinTime }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKINTIME, value) }
                onBlur={ () => this.validationDate(FIELDS.CHECKINTIME, data.CheckinTime) }
                className={ `componentHook ${validation.CheckinTime ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKIN_TIME }</label>
              { validCheckinTimeHtml }
            </div>
            <div className={ style['col-1-4'] }>
              <DatePicker
                dateFormat={ DEFAULTDATE }
                timeFormat={ false }
                renderDay={ this.renderDay }
                locale='ru'
                value={ data.CheckoutDate }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKOUT_DATE, value) }
                onBlur={ () => this.validationDate(FIELDS.CHECKOUT_DATE, data.CheckoutDate) }
                className={ `componentHook ${validation.CheckoutDate ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKOUT_DATE }</label>
              { validCheckoutDateHtml }
            </div>
            <div className={ style['col-1-4'] }>
              <DatePicker
                dateFormat={ false }
                timeFormat={ DEFAULTTIME }
                locale='ru'
                value={ data.CheckoutTime }
                onChange={ (value) => this.handleChangeDate(FIELDS.CHECKOUTTIME, value) }
                onBlur={ () => this.validationDate(FIELDS.CHECKOUTTIME, data.CheckoutTime) }
                className={ `componentHook ${validation.CheckoutTime ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKOUT_TIME }</label>
              { validCheckoutTimeHtml }
            </div>
          </div>
        </div>
        { this.renderGroupAccomodation() }
        <div className={ style.row }>
          { financeOperationsHtml }
        </div>
        { this.renderReportAmount() }
        <div className={ `${style.row} ${style.panel}` }>
          <h4>{ LABELS.PRICE }</h4>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                field={ FIELDS.BASEPRICE }
                value={ priceField.Base }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ LABELS.BASE }
                onChange={ this.handleChangePriceInput }
                onBlur={ this.validationPrice }
                valid={ validation.Base }
              />
            </div>
            <div className={ style['col-1-3'] }>
              <Input
                field={ priceFieldName }
                value={ priceFieldCommission }
                label={ LABELS.COMMISSION }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onChange={ this.handleChangePriceInput }
                onBlur={ this.validationPrice }
                valid={ priceValidation }
              />
            </div>
            { this.renderVatButtons() }
          </div>
        </div>
        { this.renderUploadVoucher() }
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ labelButton }
              disabled={ isLoading }
            />
          </div>
        </div>
      </div>
    );
  }
}
